<template >
  <div>
    <topnav></topnav>
    <div class="grad-wrap">
      <div class="page-grad"></div>
      <div class="page-grad grad-top"></div>
    </div>
    <div class="sec-top">
      <div class="center-head">
        <h3 class="head60">{{ $local.about.timelineTitle }}</h3>
        <div class="redline center-item mb-40px"></div>
        <p class="max700 gray-txt" v-html="$local.about.timelineSubtitle"></p>

      </div>
      <div class="timeline">

        <div v-for="item in  $local.about.timeline" :key="item._id" class="timeline-item" :class="{moveright:item.moveRight}">
          <div class="timeline-icon bigger-icon"></div>
          <p class="timeline-year">{{ item.year }}</p>
          <p class="timeline-txt" v-html="item.body"></p>
        </div>

        <div class="timeline-center-line"></div>
      </div>
    </div>
    <div class="sec-center">
      <div class="center-head">
        <h3 class="head54">{{ $local.about.brandTitle }}</h3>
        <!-- <div class="brand-top">
          <h4 class="txt35" v-html="$local.about.brandTagline">
          </h4>
          <p v-html="$local.about.brandSubtitle"></p>
        </div> -->
      </div>
      <div class="brand-wrap">
        <!-- <div class="our-your">
          <p class="our-your-txt">Our<span>Curiosity</span></p>
          <div class="our-your-icon"></div>
          <div class="our-your-line"></div>
          <div class="our-your-icon"></div>
          <p class="our-your-txt">Your<span>Gain</span> </p>
        </div> -->
        <p class="">The Exigent <span><img src="../assets/small-x.svg" alt="" />
        </span> symbolizes the opportunities we find that are hard to access or overlooked by other firms. </p><img
          src="../assets/map.svg" class="map" />
        <!-- <p class="">11{{ $local.about.mapText }}</p> -->
        <p>It also marks our striving for e<span><img  class="intxt-x"  src="../assets/small-x.svg" alt="" />
        </span>cellence. </p>
        <div class="exigent-name-wrap">
          <p><strong class="v-small-head">exigent </strong>
            adjective
            <strong><br /></strong>ex<span class="red-txt">-</span>i<span class="red-txt">-</span>gent <span class="red-txt">|</span> /ˈekzəjənt/ <br />pressing;
            demanding; requiring urgent attention.
          </p><img src="../assets/origin-conv.svg" class="map" />
        </div>
        <p class="">{{ $local.about.nameText }}</p>
      </div>
    </div>
    <div class="sec-center">
      <div class="center-head">
        <h3 class="head54">{{ $local.about.portfolioTitle }}</h3>
        <div class="redline center-item mb-40px"></div>

        <p class="max700" v-html="$local.about.portfolio"></p>
        <router-link to="/investing" class="read">Read More</router-link>
      </div>
    </div>
    <div class="sec-center">
      <div class="center-head">
        <h3 class="head54">{{ $local.about.togetherTitle }}</h3>
        <div class="redline center-item mb-40px"></div>

        <p class="max700" v-html="$local.about.together"></p>
        <router-link to="/impact" class="read">Read More</router-link>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  components: {
    Topnav,
    Botfooter
  },
  name: "About"
};
</script>
