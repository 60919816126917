<template >
  <div>
    <topnav></topnav>
    <div class="grad-wrap">
      <div class="page-grad"></div>
      <div class="page-grad grad-top"></div>
    </div>
    <!-- <div class="sec-top">
      <div class="hero-side-by"><img src="../assets/exigent-tatiana-cloth.jpg" class="hero-img" />
        <div class="hero-txt-wrap">
          <h1 class="head60 align-left" v-html="$local.investing.title.split(/\n/).join('<br>')">
          </h1>
          <div class="redline mb-40px"></div>

          <p class="txt-left max460 gray-txt" v-html="$local.investing.subtitle"></p>
        </div>
      </div>
    </div> -->



    <div class="sec-top">
            <div class="hero-center"><img src="../assets/exigent-tatiana-cloth.jpg" class="hero-img mid " />
                <div class="">
                  <h1 class="head60" v-html="$local.investing.title.split(/\n/).join('<br>')">
                    </h1>
                    <div class="redline center-item mb-40px"></div>
                    <p class="max700 gray-txt" v-html="$local.investing.subtitle"></p>
                </div>
            </div>
        </div>










    <div class="sec-center">
      <div class="center-head">
        <h3 class="head54">{{ $local.investing.focusTitle }}</h3>
        <div class="redline center-item mb-40px"></div>

        <p class="max700" v-html="$local.investing.focusSubtitle"></p>
        <img src="../assets/world-map.svg" class="world-map" />
      </div>
    </div>
    <!-- <div class="sec-center">
      <div class="center-head">
        <h3 class="head54">{{ $local.investing.caseStudiesTitle }}</h3>
        <div class="head-small-red-line"></div>
      </div>
      <p class="max700" v-html="$local.investing.caseStudiesSubtitle"></p>
      <div class="case-studies">
        <router-link :to="`/investment/${investment.slug}`" class="case-study box" v-for="investment in $local.caseStudies" :key="investment._id">
          <img :src="investment.logo.imageURL" />
        </router-link>
      </div>
    </div> -->
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  components: {
    Topnav,
    Botfooter
  },
  name: "Investing"
};
</script>
