import careers from "../views/Careers.vue";
import termsofuse from "../views/Termsofuse.vue";
import disclaimer from "../views/Disclaimer.vue";
import privacy from "../views/Privacy.vue";
import news from "../views/News.vue";
import impact from "../views/Impact.vue";
import investmentpage from "../views/Investmentpage.vue";
import investing from "../views/Investing.vue";
import teampage from "../views/Teampage.vue";
import about from "../views/About.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Team from "../views/Team.vue";
import Contact from "../views/Contact.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  { path: "/about", name: "About", component: about },
  { path: "/Team", name: "Team", component: Team },

  { path: "/team/:slug", name: "TeamPage", component: teampage },
  { path: "/investing", name: "Investing", component: investing },
  {
    path: "/investment/:slug",
    name: "InvestmentPage",
    component: investmentpage,
  },
  { path: "/impact", name: "Impact", component: impact },
  { path: "/News", name: "News", component: news },
  { path: "/privacy", name: "Privacy", component: privacy },
  { path: "/disclaimer", name: "Disclaimer", component: disclaimer },
  { path: "/terms-of-use", name: "TermsofUse ", component: termsofuse },
  { path: "/careers", name: "Careers", component: careers },
  { path: "/contact", name: "Contact", component: Contact },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash)
      return {
        behavior: "smooth",
        selector: to.hash,
        offset: { x: 0, y: 100 },
      };
    return savedPosition || { x: 0, y: 0 };
  },
});

export default router;
