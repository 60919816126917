<template >
    <div>
        <topnav></topnav>

        <div class="grad-wrap">
            <div class="loading" v-if="!investment._id"></div>

            <div class="page-grad"></div>
            <div class="page-grad grad-top"></div>
        </div>
        <div class="invest-main">
            <div class="invest-side"><img :src="investment.logo.imageURL" :alt="investment.name" class="invest-logo" />
                <p class="tagline">{{ investment.tagLine }}</p>
                <div class="invest-line"></div>
            </div>
            <div class="invest-wrap">
                <h1 class="invest-head">{{ investment.title }}</h1>
                <p class="invest-sub">{{ investment.subtitle }}</p>
                <div class="bio" v-html="investment.body">

                </div>
            </div>
        </div>
        <botfooter></botfooter>
    </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
    components: {
        Topnav,
        Botfooter
    },
    name: "InvestingPage",
    mounted() {
        this.getInvestment();
    },
    data() {
        return {
            investment: {},
        };
    },
    watch: {
        "$route.params"() {
            this.investment = {};
            setTimeout(() => {
                this.getInvestment();
            }, 1);
        },
    },
    methods: {
        getInvestment() {
            const { slug } = this.$route.params;
            this.investment = this.$local.caseStudies.find((m) => m.slug === slug);
        },
    },
};

</script>
