<template >
  <div>
    <topnav></topnav>
    <div class="grad-wrap">
      <div class="page-grad"></div>
      <div class="page-grad grad-top"></div>
    </div>
    <div class="sec full-page">
      <div class="careers">
        <h3 class="head54">{{ $local.careers.title }}
        </h3>
        <p class="max700" v-html="$local.careers.body"></p>
        <a :href="`mailto:${$local.careers.email}`" class="read less-space">{{ $local.careers.email }}</a>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  components: {
    Topnav,
    Botfooter
  },
  name: "Careers"
};
</script>
