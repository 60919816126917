<template >
    <div>
        <topnav></topnav>
        <div class="grad-wrap">
            <div class="page-grad"></div>
            <div class="page-grad grad-top"></div>
        </div>





        <div class="sec-side top">
            <div class="center-head">
        <h3 class="head60">Follow Us</h3>
        <div class="redline center-item mb-40px"></div>
        <p class="txt24 bold">{{ $local.contact.contactTagline }}</p>
        <a href="https://www.linkedin.com/company/exigentcapitalgroup/" target="_blank" class="linkedin">
          <img src="../assets/LinkedIn-Logo.svg" /></a>


          <div class="sec-side">
            <div class="center-head">
                <h3 class="head54">Press Releases</h3>
                <div class="head-small-red-line"></div>
            </div>
            <pressbox v-for="release in $local.pressReleases" :key="release._id" v-bind="release" />

        </div>


      </div>
        </div>
        <div class="sec-side top">
            <div class="center-head">
                <h3 class="head54">In the News</h3>
                <div class="head-small-red-line"></div>
            </div>
            <div class="news-wrap">
                <a v-for="news in $local.inTheNews" :key="news._id" :href="news.link" target="_blank" class="news-box box">
                    <p class="news-box-txt">{{ news.title }}</p>
                    <img :src="news.logo.imageURL" class="news-logo" />
                </a>
            </div>
        </div>

        <div class="sec-side">
            <div class="center-head">
                <h3 class="head54">Select Portfolio Coverage</h3>
                <div class="head-small-red-line"></div>
            </div>
            <!-- <div class="news-wrap">
                <a v-for="investment in $local.investments" :key="investment._id" :href="investment.link" target="_blank" class="news-box box">
                    <p class="news-box-txt">{{ investment.title }}</p>
                    <img :src="investment.logo.imageURL" class="news-logo" />
                </a>
            </div> -->
            <div class="carousel-wrap">
                <carousel :autoplay="true" :loop="true" :nav="false" :margin="50" :dots="true"
                :responsive="{0:{items:1,},900:{items:2}}">
                <a v-for="investment in $local.investments" :key="investment._id" :href="investment.link" target="_blank" class="news-box box">
                    <p class="news-box-txt">{{ investment.title }}</p>
                    <img :src="investment.logo.imageURL" class="news-logo" />
                </a>
        </carousel>
            </div>

        </div>

        <botfooter></botfooter>
    </div>
</template>

<script>
import Pressbox from '@/components/Pressbox.vue';
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import carousel from "sh-v-owl-carousel";

export default {
    components: {
        Topnav,
        Botfooter,
        Pressbox,
        carousel
    },
    name: "News"
};
</script>
