<template >
    <div>
        <topnav></topnav>
        <div class="grad-wrap">
            <div class="page-grad"></div>
            <div class="page-grad grad-top"></div>
        </div>
        <div class="sec-toper">
                    <h1 class="head60" v-html="$local.homepage.bigTitle.split(/\n/).join('<br>')">
                    </h1>
                    <div class="hero-center"><img src="../assets/ship.jpg" class="hero-img boat" />

                    <h3 class="home-smaller-head" v-html="$local.homepage.secondaryTitle">
                    </h3>
                    <div class="redline center-item mb-40px"></div>
                    <p class="max700 gray-txt" v-html="$local.homepage.subtitle"></p>
            </div>
        </div>


        <!-- <div class="sec-side">
            <div class="hero-side-by"><img src="../assets/x3.svg" class="hero-img" />
                <div class="hero-txt-wrap">
                    <h2 class="head54">{{ $local.homepage.edgeTitle }}
                    </h2>
                    <div class="redline mb-40px"></div>
                    <p class="max700 gray-txt" v-html="$local.homepage.edgeSubtitle">
                    </p><router-link to="/about" class="read">Learn More</router-link>
                </div>
            </div>
        </div> -->



        <div class="sec-center">
            <div class="hero-center">
                <div class="">
                    <h2 class="head54">{{ $local.homepage.edgeTitle }} <img src="../assets/x3.svg" class="head-img" />
                    </h2>
                    <div class="redline center-item mb-40px"></div>
                    <p class="max700 gray-txt" v-html="$local.homepage.edgeSubtitle">
                    </p><router-link to="/about" class="read">Learn More</router-link>
                </div>
            </div>
        </div>

        <div class="sec-center">
            <div class="center-head">
                <h3 class="head54">Leadership</h3>
                <div class="head-small-red-line"></div>
            </div>
            <div class="leaders-wrap">

                <router-link :to="`/team/${member.slug}`" class="leader" v-for="member in $local.team.filter((m) => m.leadership)" :key="member._id">
                    <img :src="member.profileImage.imageURL" />
                    <p class="name">{{ member.name }}<span>{{ member.degree }}</span></p>
                    <div class="title" v-html="member.title.split(/\n/).join('<br>')"></div>
                </router-link>

            </div>
            <router-link to="/team" class="box-link">Meet The Team</router-link>
        </div>
        <!-- <div class="sec-center">
            <div class="center-head">
                <h3 class="head54">{{ $local.homepage.caseStudies }}</h3>
                <div class="head-small-red-line"></div>
            </div>
            <p class="max700" v-html="$local.homepage.caseStudiesSubtitle"></p>
            <div class="case-studies">
                <router-link :to="`/investment/${investment.slug}`" class="case-study box" v-for="investment in $local.caseStudies" :key="investment._id">
                    <img :src="investment.logo.imageURL" />
                </router-link>
            </div>
            <router-link to="/investing" class="read">Read More</router-link>
        </div> -->
        <!-- <div class="sec-side">
            <div class="head-wrap">
                <h3 class="head54">In the News</h3>
                <router-link to="/news" class="read to-side">Read More</router-link>
            </div>
            <div class="small-red-line side"></div>
            <div class="carousel-wrap">
                <carousel :autoplay="true" :loop="true" :nav="false" :dots="true"
                :responsive="{0:{items:1,margin:0},900:{items:2,margin:50}}">
                <a v-for="news in $local.inTheNews" :key="news._id" :href="news.link" target="_blank" class="news-box box">
                    <p class="news-box-txt">{{ news.title }}</p>
                    <img :src="news.logo.imageURL" class="news-logo" />
                </a>
        </carousel>
            </div>
        </div> -->
        <!-- <div class="sec-side">
            <div class="hero-side-by ver-center"><img src="../assets/exigent-etching-building-exterior.jpg" class="hero-img" />
                <div class="hero-txt-wrap">
                    <h2 class="head54">{{ $local.homepage.impactTitle }}
                    </h2>
                    <div class="redline mb-40px"></div>
                    <p class="max460 gray-txt" v-html="$local.homepage.impactSubtitle">
                    </p><router-link to="/impact" class="read">Read More</router-link>
                </div>
            </div>
        </div> -->


        <div class="sec-center">
            <div class="hero-center">
                <div class="">
                    <h2 class="head54">{{ $local.homepage.impactTitle }}
                    </h2>
                    <div class="redline center-item mb-40px"></div>
                    <p class="max700 gray-txt" v-html="$local.homepage.impactSubtitle">
                    </p><router-link to="/impact" class="read">Learn More</router-link>
                </div>
            </div>
        </div>
        <botfooter></botfooter>
    </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
// import carousel from "sh-v-owl-carousel";

export default {
    components: {
        Topnav,
        Botfooter,
        // carousel
    },
    name: "Home"
};
</script>
