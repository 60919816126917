<template>
    <div class="press-box box" :class="{ openpress: openPress }" @click="openPress = !openPress">
        <img src="../assets/small-x.svg" class="press-icon">
        <p class="press-date">{{
            new Date(date).toLocaleString("en-US", {
                month: "short", day: "numeric", year: "numeric",
        
        
            })
        }}</p>
        <p class="press-head">{{ title }}</p>
        <p class="press-txt" v-html="body"></p>
    </div>
</template>
<script>
export default {
    data: () => ({
        openPress: false,
    }),
    props: [
        'date',
        'title',
        'body',
    ],
    name: "pressbox"
}
</script>