<template >
  <div>
    <topnav></topnav>
    <div class="grad-wrap">
      <div class="page-grad"></div>
      <div class="page-grad grad-top"></div>
    </div>
    <div class="sec-center">
      <div class="center-head">
        <h1 class="head54">Disclaimer</h1><img src="../assets/linex.svg" class="legal-img" />
      </div>
      <div class="legal" v-html="$local.disclaimer.body">

      </div><router-link to="/" class="box-link">Close</router-link>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  components: {
    Topnav,
    Botfooter
  },
  name: "Disclaimer"
};
</script>
