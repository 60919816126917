<template >
  <div>
    <topnav></topnav>
    <div class="grad-wrap">
      <div class="page-grad"></div>
      <div class="page-grad grad-top"></div>
    </div>


    <div class="sec-toper">
            <div class="hero-center"><img src="../assets/exigent-etching-building-exterior.jpg" class="hero-img mid" />
                <div class="">
                    <h1 class="head60" v-html="$local.impact.title">
                    </h1>
                    <div class="redline center-item mb-40px"></div>
                    <p class="max740 gray-txt" v-html="$local.impact.subtitle"></p>
                </div>
            </div>
        </div>


        
    <!-- <div class="sec-center">
      <div class="center-head">
        <h3 class="head54">{{ $local.impact.supportTitle }}</h3>
        <p class="max700">{{ $local.impact.supportSubtitle }}</p>
        <div class="head-small-red-line"></div>
        <div class="org-links-wrap">

          <a v-for="supported in  $local.impact.supportedList" :key="supported._id" :href="supported.link" target="_blank" class="org-link">
            <img :src="supported.logo.imageURL" /></a>
        </div>
      </div>
    </div> -->
    <div class="sec-toper">
      <div class="hero-center"><img src="../assets/globe.svg" class="hero-img hero-smaller" />
          <h2 class="head54">{{ $local.impact.together }}</h2>
          <div class="redline center-item mb-40px"></div>
          <p class="max740 gray-txt" v-html="$local.impact.togetherContent">
          </p>
      </div>
    </div>



    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  components: {
    Topnav,
    Botfooter
  },
  name: "Impact"
};
</script>
