<template >
  <div>
    <topnav></topnav>
    <div class="grad-wrap">
      <div class="page-grad"></div>
      <div class="page-grad grad-top"></div>
    </div>
    <div class="sec-top">
      <div class="center-head">
        <h3 class="head60">Contact</h3>
        <div class="redline center-item mb-40px"></div>
        <p class="txt24 bold">{{ $local.contact.contactTagline }}</p>
        <a href="https://www.linkedin.com/company/exigentcapitalgroup/" target="_blank" class="linkedin">
          <img src="../assets/LinkedIn-Logo.svg" /></a>
        <p class="" v-html="$local.contact.phone"></p>
          <a :href="`mailto:${$local.contact.email}`" class="read">{{ $local.contact.email }}</a>
      </div>
      <br>
      <br>
      <p class="txt24 bold">Offices</p>
      <div class="contact-line"></div>
        <div class="contact-wrap">
          <div class="align-right" v-html="$local.contact.israelOffices"></div>
          <div class="align-left" v-html="$local.contact.bahrain"></div>
        </div>
    </div>



    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  components: {
    Topnav,
    Botfooter
  },
  name: "About"
};
</script>
