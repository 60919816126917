<template>
    <div class="footer">
        <div class="footer-links">
            <!-- <router-link to="/" class="footer-link">Homepage</router-link> -->
            <!-- <div class="footer-ver-line"></div> -->
            <!-- <router-link to="/about" class="footer-link">About</router-link> -->
            <!-- <div class="footer-ver-line"></div> -->
            <!-- <router-link to="/team" class="footer-link">Team</router-link> -->
            <!-- <div class="footer-ver-line"></div> -->
            <!-- <router-link to="/investing" class="footer-link">Investing</router-link> -->
            <!-- <div class="footer-ver-line"></div> -->
            <!-- <router-link to="/impact" class="footer-link">Impact</router-link> -->
            <!-- <div class="footer-ver-line"></div> -->
            <!-- <router-link to="/news" class="footer-link">News</router-link> -->
            <!-- <div class="footer-ver-line"></div> -->
            <!-- <router-link to="/contact" class="footer-link">Contact</router-link> -->
            <!-- <div class="footer-ver-line"></div> -->
            <router-link to="/careers" class="footer-link">Careers</router-link>
            <!-- <div class="footer-ver-line"></div> -->
            <!-- <router-link to="/terms-of-use" class="footer-link">Terms of Use</router-link> -->
            <!-- <div class="footer-ver-line"></div> -->
            <!-- <router-link to="/privacy" class="footer-link">Privacy</router-link> -->
            <div class="footer-ver-line"></div>
            <router-link to="/disclaimer" class="footer-link">Disclaimer</router-link>
        </div>
        <div class="footer-logos">
            <a href="https://kandco.co/" target="_blank" class="footer-logo-link"> <img src="../assets/kco.svg">
            </a>
            <a href="https://bagelstudio.co.il/" target="_blank" class="footer-logo-link"> <img src="../assets/1bagelstudio_logo_gray.svg">
            </a>

        </div>
    </div>
</template>
<script>
export default {
    name: "botfooter"
}
</script>