<template>
    <div class="nav-wrap">
        <div class="topnav">
            <router-link to="/" class="logo">
                <img src="../assets/exigent-logo.svg"></router-link>
            <div class="nav-menu" :class="{ openmenu: opennavbar }">
                <button type="button" class="close-btn" @click="opennavbar = false">
                    <img src="../assets/close-white.svg"></button>
                <router-link to="/about" class="nav-link">About</router-link>
                <router-link to="/team" class="nav-link">Team</router-link>
                <router-link to="/investing" class="nav-link">Investing</router-link>
                <router-link to="/impact" class="nav-link">Impact</router-link>
                <!-- <router-link to="/News" class="nav-link">News</router-link> -->
                <router-link to="/contact" class="nav-link">Contact</router-link>

            </div><button type="button" class="menu-btn" @click="opennavbar = true">
                <img src="../assets/menu.svg"></button>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        opennavbar: false,
    }),
    name: "topnav"
}
</script>

<style>
.nav-link{
    border-bottom: 3px solid transparent;
    padding-bottom: 4px;
}
.nav-link.router-link-exact-active.router-link-active{
    border-bottom: 3px solid #FF0000;
}
@media screen and (max-width: 991px) {
    .nav-link.router-link-exact-active.router-link-active{
    border-bottom: none;
}}

</style>