<template >
  <div>
    <topnav></topnav>
    <div class="grad-wrap">
      <div class="page-grad"></div>
      <div class="page-grad grad-top"></div>
    </div>
    <div class="loading" v-if="!member._id"></div>

    <div class="team-page">
      <div class="team-side-list">
        <h3 class="side-head">Our Team</h3>

        <router-link :to="`/team/${member.slug}`" class="side-link" v-for="member in $local.team" :key="member._id" >
          <p class="side-name">{{ member.name }}</p>
          <p class="side-title" v-html="member.shortTitle"></p>
        </router-link> 

      </div>
      <div class="team-page-main">
        <div class="team-page-top"><img :src="member.profileImage.imageURL" class="profile-team-page" />
          <h1 class="name-page">{{ member.name }}</h1>
          <p class="page-title" v-html="member.title.split(/\n/).join('<br>')"></p>
          <div class="linkedin-row">
            <div class="row-line"></div>
            <a class="linkedin-small-link" :href="member.linkedin" target="_blank">
              <img src="../assets/linkedin-icon.svg" /></a>
            <div class="row-line"></div>
          </div>
        </div>
        <div class="bio" v-html="member.bio">

        </div>
        <a class="download" v-if="member.pdf.imageURL" download :href="member.pdf.imageURL" target="_blank">Download Bio</a>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  components: {
    Topnav,
    Botfooter
  },
  name: "Team"
  ,
  mounted() {
    this.getMember();
  },
  data() {
    return {
      member: {},
    };
  },
  watch: {
    "$route.params"() {
      this.member = {};
      setTimeout(() => {
        this.getMember();
      }, 1);
    },
  },
  methods: {
    getMember() {
      const { slug } = this.$route.params;
      this.member = this.$local.team.find((m) => m.slug === slug);
    },
  },
};
</script>
