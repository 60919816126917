<template >
  <div>
    <topnav></topnav>
    <div class="grad-wrap">
      <div class="page-grad"></div>
      <div class="page-grad grad-top"></div>
    </div>
    <div class="sec-top">
      <div class="team" id="team">
      <div class="">
        <h1 class="head60" v-html="$local.teamPage.title.split(/\n/).join('<br>')"> </h1>       
        <div class="redline center-item mb-40px"></div>

        <div class="team-top-txt-wrap">
          <p class="max700 gray-txt" v-html="$local.teamPage.subtitle"></p>
        </div>
      </div>
      <div class="team-list">

        <router-link :to="`/team/${member.slug}`" class="team-box box" v-for="member in $local.team" :key="member._id">
          <img :src="member.profileImage.imageURL" class="team-img" />
          <div class="team-txt-wrap">
            <p class="name">{{ member.name }}<span>{{ member.degree }}</span></p>
            <div class="title" v-html="member.title.split(/\n/).join('<br>')"></div>
          </div>
        </router-link>

      </div>
    </div>
    </div>






    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  components: {
    Topnav,
    Botfooter
  },
  name: "About"
};
</script>
